import { useTheme } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useQuery } from '@apollo/client';
import { subscriptionHistoryQuery } from '../../graphql';
import isEmpty from 'lodash/isEmpty';
import { SubscriptionItem } from './SubscriptionItem';

export const SubscriptionPanel = ({ venue }) => {
  const theme = useTheme();
  const variables = { venueId: venue.id };
  const { data } = useQuery(subscriptionHistoryQuery, { variables });
  const subscriptionData = data?.subscriptionHistory;
  const subscriptionHistory = subscriptionData?.subscriptionHistory;
  const activeSubscription = venue.subscription;
  const upcomingSubscription = venue.upcomingSubscription;

  return (
    <div style={{ margin: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item>
          {activeSubscription && (
            <SubscriptionItem
              subscription={activeSubscription}
              header="Current"
            />
          )}
          {upcomingSubscription && (
            <SubscriptionItem
              subscription={upcomingSubscription}
              header="Upcoming"
            />
          )}
          <div>
            <Typography variant="h4" gutterBottom>
              History
            </Typography>
            {isEmpty(subscriptionHistory) ? (
              <Typography>No subscription history</Typography>
            ) : (
              subscriptionHistory.map((subscription) => (
                <SubscriptionItem
                  key={subscription.id}
                  subscription={subscription}
                />
              ))
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
