import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from '@pv/common/hooks';
import { EditablePanel, Fields } from '@pv/common/components';
import { updateOrganizationMutation } from '../graphql';
import { PlanSetsTable } from './PlanSetsTable';
export const InfoPanel = ({ organization, availablePlanSets }) => {
  const resetValues = () => {};
  const [name, setName] = useState('');
  const [discount, setDiscount] = useState(
    (organization?.discount || 0.0) * 100
  );
  const [planSetId, setPlanSetId] = useState(organization?.planSet?.id);

  const availablePlanSetVals = availablePlanSets?.map((planSet) => [
    planSet.id,
    planSet.name,
  ]);

  useEffect(() => {
    setName(organization?.name || '');
    setDiscount((organization?.discount || 0.0) * 100);
    setPlanSetId(organization?.planSet?.id);
  }, [organization]);

  const [updateOrganization, { loading: updateLoading }] = useMutation(
    updateOrganizationMutation,
    {
      onNoErrorsCompleted: () => {
        enqueueSnackbar('Updated Organization');
      },
    }
  );

  const loading = updateLoading;

  const onSave = () => {
    const input = {
      id: organization.id,
      name,
      planSetId: planSetId,
      discount: discount ? discount / 100 : null,
    };
    const variables = { input };

    updateOrganization({ variables });
  };

  return (
    <div>
      <EditablePanel
        name="organization"
        onSave={onSave}
        onClear={resetValues}
        loading={loading}
        title="Organization Info"
      >
        {({ editing }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={!editing}
                required
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Fix field */}
              <Fields.PvPercentageField
                disabled={!editing}
                fullWidth
                clearable
                label="Discount to be applied to all venues"
                name="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={true}
                label="Billing Email"
                value={organization.billingEmail}
                name="email"
              />
            </Grid>

            <Grid item xs={6}>
              <Fields.PvDropDown
                disabled={!editing}
                vals={availablePlanSetVals}
                label="Plan Set"
                name="plan-set"
                value={planSetId || ''}
                onChange={(e) => setPlanSetId(e.target.value)}
                helperText="Plan Sets are for orgs that have made a deal with sales for a specific set of prices"
              />
            </Grid>
          </Grid>
        )}
      </EditablePanel>
      <PlanSetsTable planSets={availablePlanSets} />
    </div>
  );
};
