import { gql } from '@apollo/client';

const subscriptionFragment = gql`
  fragment SubscriptionFields on SubscriptionPlan {
    id
    planDefinitionId
    planName
    tier
    billingInterval
    monthlyRate
    cardApplicationFeeRate
    achApplicationFeeRate
    stripeSourceId
    startDate
    endDate
    premiumFeaturesEnabled
    basicFeaturesEnabled
    proFeaturesEnabled
    stripeSubscriptionId
  }
`;

export const adminVenueQuery = gql`
  query AdminVenue($id: ID!) {
    adminVenue(id: $id) {
      id
      name
      slug
      email
      trialEndDate
      subscription {
        ...SubscriptionFields
      }
      customerStartedAt
      achEnabled
      defaultPaymentProvider
      paymentsEnabled
      collections {
        id
      }
      stripeAccount {
        id
        name
        stripeAccountId
      }
      websiteUrl
      users {
        id
        role
        email
        firstName
        lastName
        magicLoginLink
      }
      organization {
        id
        name
      }
      suspicious
      featureFlags
      squareAccount {
        id
        squareMerchantId
      }
    }
  }
  ${subscriptionFragment}
`;

export const subscriptionHistoryQuery = gql`
  query SubscriptionHistory($venueId: ID!) {
    subscriptionHistory(venueId: $venueId) {
      ...SubscriptionFields
    }
  }
  ${subscriptionFragment}
`;

export const timeSeriesMetricsQuery = gql`
  query TimeSeriesMetrics($subjectId: ID, $subjectType: String) {
    timeSeriesMetrics(subjectId: $subjectId, subjectType: $subjectType) {
      id
      name
      unit
    }
  }
`;

export const removeVenueMutation = gql`
  mutation RemoveVenue($input: RemoveVenueInput!) {
    removeVenue(input: $input) {
      venue {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const disconnectStripeAccountMutation = gql`
  mutation DisconnectStripeAccount($input: DisconnectStripeAccountInput!) {
    disconnectStripeAccount(input: $input) {
      venue {
        id
        stripeAccount {
          id
          name
          stripeAccountId
        }
      }
      errors {
        message
      }
    }
  }
`;
